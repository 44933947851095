@import '../../../styles/Functions.scss';

.reason {
  display: flex;
  align-items: center;
  padding: rem(10) rem(20) !important;
  .color {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    label {
      display: block;
      width: rem(12);
      height: rem(12);
      margin-right: rem(8);
      border-radius: 50%;
      cursor: pointer;
    }
    input {
      position: absolute;
      padding: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
  .name {
    margin-right: rem(8) !important;
    flex: 1;
    input {
      border-color: var(--border);
    }
  }
  .day {
    margin-right: rem(8) !important;
    display: flex;
    align-items: center;
    position: relative;
    input {
      border-color: var(--border);
      border-radius: rem(4) 0 0 rem(4);
      width: rem(60);
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    span {
      height: 100%;
      flex: 1;
      display: flex;
      border: rem(1) solid var(--border);
      border-left: none;
      border-radius: 0 rem(4) rem(4) 0;
      padding: rem(4) rem(6);
      background-color: var(--background);
    }
  }
  .actions {
    display: flex;
    margin-left: 0;
    width: 0;
    overflow: hidden;
    border-radius: rem(4);
    transition: 0.2s;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 2rem;
      width: 2rem;
      &.save {
        background-color: var(--green);
        border-color: var(--green);
        margin-right: rem(8);
      }
      &.cancel {
        background-color: var(--red);
        border-color: var(--red);
      }
    }
    :global {
      .ant-btn-loading > svg {
        display: none;
      }
      .ant-btn-loading-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          padding: 0;
        }
      }
    }
    &.active {
      width: rem(72);
      margin-left: rem(8);
    }
  }
  :global {
    .ant-form-item {
      margin: 0;
      input {
        border-radius: rem(4);
      }
    }
  }
}
