.modal {

    &_title {
        margin-bottom: 20px;

        h4 {
            color: #101828;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
        }
    }

    :global {

        .ant-select-single{
            .ant-select-selector{
                .ant-select-selection-item, 
                .ant-select-selection-placeholder {
                    line-height: 20px;
                }
            } 
        } 

        .ant-form-item-control-input-content{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ant-modal-close {
            display: none;
        }

        .ant-form-item {
            margin-bottom: 16px;
        }

        .ant-modal-content {
            box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
            border-radius: 12px;
        }

        .ant-modal-header {
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
        }

        .ant-input {
            padding: 10px 14px;
            border-radius: 8px;
            resize: none;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            height: 44px;
            padding: 10px 14px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
    }

    &_btns {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 32px;

        button {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            color: #344054;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;

            &:last-child {
                background-color: #7F56D9;
                color: #fff;
            }
        }
    }
}