@import '../../../../styles/Functions';

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  &_top {
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    padding: rem(6) rem(20);
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 10px 30px rgba(17, 38, 146, 0.05);

    button {
      border-radius: rem(4);
      height: rem(25);
      padding-top: 0;
      padding-bottom: 0;

      span {
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(20);
      }
    }
  }

  &_bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: rem(10);
    gap: rem(10);
  }
}