.container{
    background-color: #fff;
    padding: 15px 0;
    height: calc(100vh - 40px);
}

.navigate{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    p{
        line-height: normal;
    }
}

:global{
    .ant-message{
        span{
            white-space: break-spaces;
        }
    }
}