.table {
  p {
    margin-bottom: 0;
  }

  .statues {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      padding: 2px 8px;
      border-radius: 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }

    &_ssldate {
      background-color: #F9F5FF;
      color: #6941C6;
    }

    &_domain {
      background-color: #EFF8FF;
      color: #175CD3;
    }

    &_server {
      background-color: #EEF4FF;
      color: #3538CD;
    }
  }

  .active {
    background: #ECFDF3;

    .dot {
      circle {
        fill: #12B76A;
      }
    }
  }

  .disactive {
    background-color: #FEF3F2;

    .dot {
      circle {
        fill: #D92D20;
      }
    }
  }

  .active,
  .disactive {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: max-content;
    gap: 7px;
    padding: 2px 8px;
    border-radius: 16px;
  }



  :global {

    .ant-pagination {

      .ant-pagination-next,
      .ant-pagination-prev {
        width: 6.75rem;
      }
    }


    .ant-table-thead {
      .ant-table-filter-column {
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .ant-table-column-title {
          flex: inherit;
          line-height: 20px;
          color: #667085;
        }

        .ant-table-filter-trigger {
          padding: 0;
          margin: 0;

          &:hover {
            background: transparent;
          }
        }
      }

      .ant-table-cell {
        color: #667085;
        cursor: pointer;

        &::before {
          width: 0 !important;
        }
      }
    }

    .ant-table-cell {
      a {
        font-size: 14px;
        line-height: 20px;
        color: #101828;
        font-weight: 500;
      }
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 6px;
    }

    .ant-table-selection-column {
      padding-left: 24px;
    }
  }

  .actions {
    &_icon {
      border-radius: 4px;
      transition: 0.4s;

      &:hover {
        background-color: #d6d6d6;
        cursor: pointer;
      }
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
      border-radius: 12px;

      .ant-modal-header {
        border-radius: 12px 12px 0 0;
      }
    }
  }

  &_btns {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 15px;

    button {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #344054;

      &:last-child {
        &:hover {
          color: #D92D20;
          border-color: #D92D20;
        }
      }
    }
  }
}

.website {
  &_pagination {
    :global {
      .ant-pagination-options-size-changer {
        display: none !important;
      }
      .ant-pagination-next,
       .ant-pagination-prev {
        background-color: #fff;
    }
    }
  }
}