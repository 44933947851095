@import '../../../../../styles/Functions';

.total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-weight: 400;
  font-size: rem(30);
  line-height: rem(36);
}