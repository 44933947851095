@import '../../../styles/Functions.scss';

.employees_table {
  .employee_img {
    width: rem(45);
    height: rem(45);
    border-radius: rem(8);
    object-fit: contain;
  }
  .edit_btn {
    display: flex;
    align-items: center;
    height: rem(25);
    padding: 0 1rem;
    box-shadow: 0 rem(2) rem(4) rgba(58, 87, 232, 0.25);
    border-radius: rem(4);
    margin: 0 auto;
    svg {
      margin-right: rem(8);
    }
    span {
      font-weight: 400;
      font-size: rem(12);
      line-height: 175%;
    }
  }
  :global {
    .ant-table-pagination {
      margin: 1rem 0 0 !important;
    }
    .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 10;
      .ant-table-cell {
        border: none;
        color: var(--grey);
        background-color: #f5f6fa;
        &::before {
          display: none;
        }
      }
    }
  }
}
