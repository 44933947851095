@import "../../styles/Functions.scss";

.gantt {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 100vh;
  background-color: #fafbfc;
  &_header {
    position: sticky;
    left: 0;
    background-color: #fafbfc;
    top: 0;
    z-index: 11;
  }
  &_body {
    display: flex;
    flex-direction: column;
    width: max-content;
    background-color: #fafbfc;
    position: relative;
    &_top {
      display: flex;
      border: rem(1) solid #ebebeb;
      position: sticky;
      top: 22px;
      z-index: 11;
      background-color: #fafbfc;
      .left {
        max-width: rem(384);
        min-width: rem(384);
        position: sticky;
        left: 0;
        background-color: var(--white);
      }
      .right {
        display: flex;
        flex-direction: column;
        .weeks {
          display: flex;
          border-bottom: rem(1) solid #ebebeb;
          &_item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(175);
            height: rem(22);
            border-right: rem(1) solid #ebebeb;
            font-weight: 500;
            font-size: rem(11);
            color: #292d34;
            box-sizing: border-box;
          }
        }
        .days {
          display: flex;
          position: relative;

          &_item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(22);
            width: rem(25);
            border-right: rem(1) solid #ebebeb;
            box-sizing: border-box;
            color: #7c828d;
            font-size: rem(11);
            font-weight: 500;
          }
        }
      }
    }
    &_bottom {
      display: flex;
      flex-direction: column;
      background-image: url(./assets/images/bg.png);
      background-position-x: 10px;
      position: relative;
      .gantt_row {
        display: flex;
        flex-direction: column;
        .top {
          flex: 1;
          display: flex;
          .left {
            flex: 1;
            display: flex;
            align-items: center;
            column-gap: rem(6);
            max-width: rem(384);
            min-width: rem(384);
            background-color: var(--white);
            padding: 0 rem(50);
            position: sticky;
            left: 0;
            height: rem(35);
            z-index: 10;
            svg {
              width: 1rem;
            }
            span {
              display: flex;
              width: rem(8);
              min-width: rem(8);
              height: rem(8);
              border-radius: rem(2);
              margin-right: 15px;
              pointer-events: none;
            }
            p {
              margin: 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          .right {
            flex: 1;
            white-space: nowrap;
          }
        }
        .bottom {
          display: flex;
          flex-direction: column;
        }
      }
      .space {
        user-select: none;
        .top {
          .left {
            cursor: pointer;
            &_open_icon {
              cursor: pointer;
              position: relative;
              &::after {
                content: "";
                height: 0;
                width: 0;
                border-color: transparent;
                border-style: solid;
                border-width: 4px;
                border-top-color: #7c828d;
                top: 17px;
                border-radius: 2px;
              }
            }
            &_close_icon {
              cursor: pointer;
              position: relative;
              &::after {
                content: "";
                height: 0;
                width: 0;
                border-color: transparent;
                border-style: solid;
                border-width: 4px;
                border-left-color: #7c828d;
                top: 14px;
                left: 7px;
                border-radius: 2px;
              }
            }
          }
          .right {
            position: relative;
            &:hover {
              background: #e9ebf090;
            }
            &_line {
              background-color: #e9ebf0;
              height: 8px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 10px;
              > span {
                height: 100%;
                display: block;
                border-radius: 10px;
                background-color: rgb(220, 219, 221);
              }
            }
          }
        }
      }
      .folder {
        .top {
          .left {
            padding-left: rem(65);
          }
          .right {
            position: relative;
            &:hover {
              background: #e9ebf090;
            }
            &_line {
              background-color: #e9ebf0;
              height: 8px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 10px;
              > span {
                height: 100%;
                display: block;
                border-radius: 10px;
                background-color: rgb(172, 171, 173);
              }
            }
          }
        }
      }
      .list {
        .top {
          .left {
            padding-left: rem(80);
          }
          .right {
            position: relative;
            &:hover {
              background: #e9ebf090;
            }
            &_line {
              background-color: rgb(166, 222, 150);
              height: 8px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 10px;
              > span {
                height: 100%;
                display: block;
                border-radius: 10px;
                background-color: rgb(107, 201, 80);
              }
            }
          }
        }
      }
      .task {
        position: relative;
        user-select: none;
        .top {
          .left {
            padding-left: rem(95);
          }
          .right {
            display: flex;
            align-items: center;
            position: relative;
            .cells {
              display: flex;
              position: absolute;
              height: 100%;
              &_item {
                width: rem(25);
                border-right: rem(1) solid #ebebeb;
              }
            }
            .task {
              position: absolute;
              height: rem(26);
              border-radius: rem(12);
              padding: 0 rem(10);

              font-size: rem(12);
              font-weight: 500;
              line-height: rem(26);
              p {
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(26);
                white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                color: var(--white);
                margin: 0;
              }
            }
          }
        }
        &:hover {
          .task_dragging_left,
          .task_dragging_right {
            opacity: 1;
          }
        }
        &_rightText {
          position: absolute;
          left: 110%;
        }
        &_dragging {
          &_left {
            opacity: 0;
            transition: 0.3s;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: e-resize;
            width: 12px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.3);
            border-radius: rem(12) 0 0 rem(12);
          }
          &_right {
            opacity: 0;
            transition: 0.3s;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: e-resize;
            width: 12px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 0 rem(12) rem(12) 0;
          }
        }
      }
    }
  }
}
.today {
  color: #fff;
  position: absolute;
  height: 100%;
  pointer-events: none;
  top: 0;
  z-index: 1;
  margin-left: 24rem;
  > span {
    font-size: 12px;
    line-height: 12px;
    padding: 5px;
    height: rem(25);
    background-color: #7b68ee;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #7b68ee;
  }
}
.line {
  &_title {
    position: fixed;
    pointer-events: none;
    padding: 6px 8px;
    color: #fff;
    text-align: start;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    bottom: calc(100% + 10px);
    z-index: 100;
    height: max-content;
  }
}
