@import '../../../../styles/Functions';

.sidebar {
  display: flex;
  flex-direction: column;
  width: rem(200);
  min-width: rem(200);
  height: 100%;
  background-color: var(--white);

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(40);
    padding: 0 rem(10);
    margin: rem(6) 0;

    p {
      font-weight: 400;
      font-size: rem(12);
      line-height: rem(15);
      color: var(--black);
      margin: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: rem(25);
      width: rem(25);
      padding: 0;

      span {
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(20);
      }
    }
  }

  &_bottom {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: rem(34);
      padding: 0 1rem;
      background-color: transparent;
      transition: 0.3s ease-in-out;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(124, 130, 141, 0.1);
      }

      &:hover {
        background-color: #CBE9FF;

        .more {
          opacity: 1;
        }
      }

      &.active {
        background-color: #CBE9FF;
      }

      span {
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(14);
        color: var(--black);
      }

      .more {
        transition: 0.3s ease-in-out;
        opacity: 0;
      }

    }
  }
}

.popover {

  :global {
    .ant-popover-inner {
      border-radius: rem(4);
      overflow: hidden;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }


  .popover_content {
    display: flex;
    flex-direction: column;

    span {
      cursor: pointer;
      padding: rem(4) rem(22);
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: #F9FAFB;
      }
    }
  }
}
