.container {
  position: fixed;
  pointer-events: none;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  bottom: calc(100% + 10px);
  z-index: 200;
  height: max-content;
  transition: 0.3s opacity;
  &_arrow {
    position: absolute;
    left: 50%;
    width: 20px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.85);
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
}
