@import './Functions.scss';

.d_f {
  display: flex;
}

.d_n {
  display: none !important;
}

.m_0 {
  margin: 0;
}

.w_100 {
  width: 100%;
}

// ant pagination
.ant-pagination {
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(38);
    margin: 0;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: rem(60);
    border: rem(1) solid var(--border);
  }
  .ant-pagination-prev {
    border-radius: rem(8) 0 0 rem(8);
  }
  .ant-pagination-next {
    border-radius: 0 rem(8) rem(8) 0;
    border-left: none;
  }
  > .ant-pagination-item {
    width: rem(35);
    border-radius: 0;
    border: rem(1) solid var(--border);
    border-left: none;
    a {
      font-weight: 400;
      font-size: 1rem;
      line-height: 175%;
      color: var(--primary);
    }
    &-active {
      background-color: var(--primary);
      a {
        color: var(--white);
      }
    }
  }
}

// antd message
.ant-message-notice {
  &-content {
    padding: 0;
    border-radius: rem(4);
    overflow: hidden;
    .ant-message-custom-content {
      padding: rem(10) 1rem;
      color: var(--white-font);
      background: var(--primary);
      svg {
        fill: var(--white);
      }
    }
    .ant-message-error {
      background-color: var(--red);
    }
    .ant-message-success {
      background-color: var(--green);
    }
  }
}

// common modal
.common_modal {
  box-shadow: 0 rem(10) rem(13) rgba(17, 38, 146, 0.05);
  .ant-modal-content {
    border-radius: rem(8);
    .ant-modal-body {
      padding: rem(24) rem(22);
    }
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    p {
      font-weight: 600;
      font-size: rem(16);
      line-height: rem(20);
      color: var(--primary);
      margin: 0;
    }
    svg {
      cursor: pointer;
    }
  }
  &_body {
    margin-bottom: rem(12);
  }
  &_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .submit_btn {
      height: rem(40);
      border-radius: rem(4);
      font-weight: 400;
      font-size: 1rem;
      line-height: rem(24);
      color: var(--white-font);
      text-shadow: unset;
      padding: 0 rem(24);
    }
  }
}

// time picker
.ant-picker-time-panel-column {
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    background: transparent;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 0.5rem;
  }
  &.ant-scrolling-effect {
    width: calc(100% - 0.2rem) !important;
    .header {
      width: calc(100% - 0.2rem) !important;
    }
  }
}

// common btn
.common_btn {
  height: 2.5rem;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--white-font);
  text-shadow: unset;
  padding: 0 1.5rem;
}

// ant image preview
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.9);
}
