@import "../../../../styles/Functions";

.strike {
  display: flex;
  align-items: flex-start;

  .user {
    border: 0.5px solid rgba(0, 0, 0, 0.20);
    width: rem(345);
    padding: 0 rem(14);
    box-sizing: border-box;
    height: 64px;
    cursor: pointer;

    &_item {
      display: flex;
      align-items: center;
      row-gap: 30px;
      column-gap: 10px;
      margin-top: 10px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50px;
      }

      p {
        margin: 0;
      }

      div {
        p {
          color: #292D34;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        span {
          color: #7C828D;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

.tasklist {
  width: 1550px;
  margin-right: 50px;

  &_top {
    display: flex;
    height: 64px;
    width: 104%;

    > div {
      display: flex;
      padding: 10px 5px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      border: 0.5px solid rgba(0, 0, 0, 0.20);
      height: 100%;

      > span {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 5px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 3px;
        background: #A3F5DF;
      }
    }
  }

  &_bottom {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 104% !important;
  }
}

.task {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #3A57E8;
  background: #3a57e833;
  padding: 5px 0 0 5px;
  border-radius: 4px;

  &_fd {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
  }
}

.circle {
  min-width: 16px;
  min-height: 16px;
  background-color: red;
  border-radius: 50px;
  margin: 5px 2px 0 0;
}

.task_hour {
  p {
    margin: 5px 3px 0 0;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.task_name {
  margin: 5px 0 10px 5px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #F04438;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}

.delete {
  display: flex !important;
  border: none !important;
  column-gap: 10px;
  background: none !important;
  width: 100% !important;

  svg {
    margin-left: -15px
  }

  p {
    margin-right: 100px !important;
  }
}

:global {
  .ant-dropdown {
    min-width: 90px !important;
  }
}