@import "../../styles/Functions.scss";

.main_layout {
  height: 100vh;

  &_sidebar {
    background-color: var(--white) !important;
    box-shadow: 0 rem(10) rem(30) rgba(17, 38, 146, 0.05);

    :global {
      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
      }
    }

    &_header {
      display: flex;
      align-items: center;
      position: relative;
      height: rem(76);
      padding: 0 rem(32);
      border-bottom: rem(1) solid var(--border);
      box-sizing: content-box !important;

      svg.logo {
        min-width: rem(28);
        min-height: rem(28);
        margin-right: rem(8);
        position: relative;
        right: rem(12);
        transition: 0.2s;

        &.active {
          right: 0;
        }
      }

      p {
        font-weight: 500;
        font-size: 33px;
        line-height: 130%;
        color: #232d42;
        margin: 0;
        overflow: hidden;

        &.active {
          width: rem(100);
        }
      }

      .trigger {
        position: absolute;
        right: rem(-23);
        padding: rem(8);
        cursor: pointer;
        transition: 0.2s;
        z-index: 1000;

        &.collapsed {
          transform: rotate(180deg);
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: rem(30);
          width: rem(30);
          background: var(--primary);
          border-radius: 50%;
          filter: drop-shadow(0 rem(2) rem(4) rgba(138, 146, 166, 0.3));
        }
      }
    }

    &_body {
      padding: rem(24) rem(12);

      .title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 175%;
        color: var(--light-grey);
        padding: 0 rem(20);
        margin-bottom: rem(8);
      }

      .menu {
        border-right: unset;

        :global {

          .ant-menu-item {
            margin-bottom: rem(4) !important;
            margin-top: rem(4);
            padding: 0 rem(24);
            height: rem(44);
            border-radius: rem(4);

            &::after {
              display: none;
            }

            &:hover {
              background-color: var(--primary-1) !important;

              svg path {
                fill: var(--primary);
              }
            }

            &-icon {
              display: flex;
            }

            svg {
              transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              margin 0.3s, color 0.3s;
            }
          }


          .ant-menu-item-selected,
          .ant-menu-submenu-open {
            background-color: var(--primary) !important;
            color: var(--white);

            svg path {
              fill: var(--white);
            }

            &:hover {
              color: var(--white);
              background-color: var(--primary) !important;

              svg path {
                fill: var(--white);
              }
            }
          }
        }
      }

      :global {
        .ant-menu-inline-collapsed {
          .ant-menu-item {
            .ant-menu-item-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              svg {
                min-width: rem(24);
              }
            }
          }
        }
      }
    }

    &_footer {
      margin-top: auto;
      padding: 12px;
      box-sizing: border-box;

      &_clickUpButton {
        background: #1877f2 !important;
        color: #ffffff !important;
        border-radius: 4px;
        display: flex !important;
        align-items: center;

        &:hover {
          background: #1877f2 !important;
          color: #ffffff !important;
        }

        :global {
          .ant-menu-item-icon {
            display: flex !important;
            align-items: center;
          }
        }
      }
    }
  }

  &_content {
    background-color: var(--background) !important;

    &.simple {
      .main_layout_content_header {
        display: none;
      }

      .main_layout_content_body {
        margin: 0;
      }
    }

    &_header {
      height: rem(76) !important;
      background-color: var(--white) !important;
      padding: 0 2rem !important;
    }

    &_body {
      //margin: rem(20) rem(40);
      overflow: auto;

      &::-webkit-scrollbar {
        width: rem(5);
        height: rem(5);
        background: transparent;
        position: absolute;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--light-grey);
        border-radius: rem(8);
      }

      &.ant-scrolling-effect {
        width: calc(100% - 5px) !important;

        .header {
          width: calc(100% - 5px) !important;
        }
      }
    }
  }
}