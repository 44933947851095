@import '../../../styles/Functions.scss';

.time_off_modal {
  width: rem(550) !important;
  .body {
    :global {
      .ant-form {
        &-item {
          margin: 0;
          &:not(:last-child) {
            margin-bottom: rem(12);
          }
          &-label {
            padding-bottom: rem(3);
            label {
              font-weight: 500;
              font-size: rem(14);
              line-height: rem(20);
              color: var(--light-grey);
              &::before {
                display: none !important;
              }
            }
          }
          .ant-select-selector {
            border-color: var(--border);
            border-radius: rem(4);
            height: rem(34);
            input {
              height: 100%;
            }
          }
          input {
            border-color: var(--border);
            border-radius: rem(4);
            height: rem(34);
            width: 100%;
          }
          textarea {
            border-color: var(--border);
            border-radius: rem(4);
            min-height: rem(104);
          }
        }
        .time_cont {
          display: flex;
          align-items: flex-end;
          .ant-select {
            width: rem(83);
            &-selector {
              height: rem(34);
              .ant-select-selection-search {
                display: flex;
                align-items: center;
                input {
                  height: 100%;
                }
              }
              .ant-select-selection-item {
                display: flex;
                align-items: center;
                line-height: 150;
              }
            }
          }
          .ant-form-item {
            margin-bottom: rem(12);
            &:not(:last-child) {
              margin-right: rem(24);
            }
          }
          .ant-picker {
            width: 100%;
            border-radius: rem(4);
            padding: 0 rem(12);
            border-color: var(--border);
            height: rem(34);
            &-status-error {
              border-color: var(--ant-error-color);
            }
            input {
              height: 100%;
            }
          }
        }
      }
    }
  }
  .footer {
    justify-content: space-between;
  }
}
