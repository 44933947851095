.container{
    background-color: #fff;
    position: relative;
    &_nav{
        display: flex;
        align-items: center;
        gap: 20px;
        position: absolute;
        top: 10px;
        right: 20px;
        &_parent{
            p{
                margin-bottom: 0;
                font-size: 10px;
                text-transform: uppercase;
                color: #7C828D;
            }
            &_child{
                width: 126px;
                height: 20px;
                border-radius: 10px;
                position: relative;
                &_text {
                    position: relative;
                    z-index: 1;
                }
            
                &_protsent {
                    border-radius: 50px;
                    top: 0;
                    left: 0px;
                    height: 100%;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 12px;
                    line-height: 0;
                }
            }
        }
    }
    &_header{
        display: flex;
        flex-direction: column;
        gap: 5px;
        color: #292D34;
        &_title{
            font-size: 12px;
            line-height: 14px;
            color: #292D34;
            font-weight: 500;
        }
        &_dot{
            width: 10px;
            height: 10px;
            background: #D9D9D9;
            border-radius: 50%;
        }
    }
    
    :global{
        .fc-scrollgrid-shrink{
            border: none;
            border-top-style: none;
        }
        .fc-col-header-cell-cushion{
            display: none;
        }
        .fc-daygrid-event{
            border-radius: 0;
        }
        .fc-timegrid-slot-label{
            position: relative;
            border: none !important;
            .fc-scrollgrid-shrink-cushion{
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
            }
        }
        .fc-timegrid-slot-lane{
            border: 1px solid #E0E0E0;
        }
        .fc-toolbar-title{
            width: 100px;
        }

        .fc-daygrid-event-harness{
            .fc-event-main{
                span{
                    &:nth-child(2){
                        display: none;
                    }
                }
            }
        }
        .fc-timegrid-axis-cushion {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: bold;
        }
        .fc-view-harness{
            height: 686px !important;
        }
        .fc-header-toolbar{
            margin: 0 !important;
            padding: 15px 20px;
            justify-content: flex-start !important;
            gap: 10px;
            .fc-toolbar-title{
                font-size: 14px;
            }
            .fc-today-button{
                border: 1px solid gray !important; 
                padding: 2px 5px !important;
                text-transform: capitalize;
                cursor: pointer;
                &:focus{
                    box-shadow: none;
                }
            }
            .fc-button-group{
                margin-left: 0;
                button{
                    padding: 0;
                }
            }
            .fc-button-primary{
                color: #7C828D !important;
                background-color: transparent !important;
                border: none;
                &:disabled{
                    color: #7C828D;
                    background-color: transparent;
                }
                &:not(:disabled){
                    &:disabled{
                        color: #7C828D;
                        background-color: transparent;
                    }
                    &:active{
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
                &:active{
                    &:focus{
                        box-shadow: none;
                    }
                    color: #7C828D !important;
                    background-color: transparent !important;
                    border: none;
                    box-shadow: none;
                }
                &:focus{
                    box-shadow: none;
                }
                &:hover{
                    color: #7C828D;
                    background-color: transparent;
                }
            }
        }
        .fc-scroller-harness{
            ::-webkit-scrollbar{
                width: 6px;
                background-color: #fff;
            }
            ::-webkit-scrollbar-thumb{
                width: 6px;
                background-color: #D9D9DC;
                border-radius: 3px;
            }
            .fc-timegrid-slot-label-frame{
                text-align: center;
            }
            .fc-daygrid-body,
            .fc-timegrid-body,
            .fc-col-header-cell,
            .fc-daygrid-day-frame,
            .fc-day
            {
                width: 510px !important;
            }
            .fc-daygrid-day-frame{
                .fc-daygrid-event-harness{
                    margin-bottom: 2px;
                    .fc-event-main{
                        .dots{
                            display: none !important;
                        }
                    }
                }
            }
            .fc-timegrid-slot{
                height: 39px;
                border: 1px solid #E0E0E0;
            }
            .fc-v-event{
                background: linear-gradient(0deg, rgba(4, 169, 244, 0.2), rgba(4, 169, 244, 0.2)), #FFFFFF;
                border: 1px solid rgba(4, 169, 244, 0.5);
                border-radius: 5px;
                padding: 8px 5px;
                overflow: hidden;
            }
            .fc-h-event{
                background-color: #93DAFB;
                border: none;
                border-left: 1px solid #04A9F4;
                height: 26px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-bottom: 0;
            }
        }
    }
}