.request_table{
    position: relative;
    .title{
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        font-size: 12px;
        line-height: 14px;
        color: #7C828D;
        text-transform: uppercase;
        font-weight: 400;
    }
}

.timeOff_container{
    margin-top: 28px;
    :global{
        table{
            padding-top: 34px !important;
        }
        .ant-table-cell{
            font-size: 12px;
            color: #292D34;
            font-weight: 400;
            padding: 10px 3px !important;
            background-color: var(--white);
            &::before{
                width: 0 !important;
            }
            &:first-child{
                padding-left: 10px !important;
            }
            .ant-avatar{
                width: 26px;
                height: 26px;
            }
        }
    }
    p{
        margin: 0;        
    }

    .member{
        display: flex;
        align-items: center;
        gap: 5px;
        h5{
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }

    .time_off_status{
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        padding: 2px 24.5px;
        border-radius: 2px;
        width: 108px;
        cursor: pointer;
    }

    .expected{
        background-color: #F16A1B;
    }
    .confirmed{
        background-color: #00C875;
    }
    .rejected{
        background-color: #ea3e53;
    }
}