@import '../../styles/Functions.scss';

.time_off_layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  .body {
    display: flex;
    flex-direction: column;
    background: var(--white);
    border-radius: rem(8);
    padding: rem(24);
    height: 100%;
    &_topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: rem(24);
      .left {
        .tabs {
          display: flex;
          justify-content: space-between;
          background-color: var(--primary);
          border-radius: rem(4);
          height: rem(40);
          overflow: hidden;
          a {
            display: flex;
            align-items: center;
            padding: 0 rem(24);
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(24);
            color: var(--white-font);
            cursor: pointer;
            height: 100%;
            &.active {
              background-color: var(--primary-dark);
            }
          }
        }
      }
      .center {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: rem(5);
          cursor: pointer;
          svg {
            transition: 0.2s;
            &:hover {
              path,
              rect {
                stroke: var(--primary);
              }
            }
          }
        }
        :global {
          .ant-picker {
            width: rem(280);
            padding: 0;
            input {
              font-weight: 600;
              font-size: rem(28);
              line-height: 130%;
              text-align: center;
            }
            span {
              display: none;
            }
          }
        }
      }
      .right {
        button {
          height: rem(40);
          border-radius: rem(4);
          font-weight: 400;
          font-size: 1rem;
          line-height: rem(24);
          color: var(--white-font);
          text-shadow: unset;
          padding: 0 rem(24);
          &:not(:last-child) {
            margin-right: rem(24);
          }
        }
      }
    }
    &_content {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0.2rem;
        height: 0.2rem;
        background: transparent;
        position: absolute;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--primary);
        border-radius: 0.5rem;
      }
      &.ant-scrolling-effect {
        width: calc(100% - 0.2rem) !important;
        .header {
          width: calc(100% - 0.2rem) !important;
        }
      }
    }
  }
}
