@import '../../../../styles/Functions.scss';

.workload_item {
  display: flex;
  border: rem(1) solid #e0e0e0;
  border-top: none;
  &_left {
    display: flex;
    flex-direction: column;
    row-gap: rem(12);
    min-width: rem(306);
    padding: rem(10) rem(14);
    cursor: pointer;
    &:hover {
      .top .info .more svg {
        opacity: 1;
      }
    }
    .top {
      display: flex;
      column-gap: rem(10);
      img {
        width: rem(36);
        height: rem(36);
        object-fit: cover;
        border-radius: 50%;
      }
      .info {
        display: flex;
        flex-direction: column;
        row-gap: rem(2);
        .name {
          font-weight: 400;
          font-size: rem(14);
          line-height: rem(17);
          margin: 0;
          color: var(--black-font);
        }
        .more {
          display: flex;
          align-items: center;
          column-gap: rem(5);
          span {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(14);
            color: var(--grey);
          }
          svg {
            opacity: 0;
            transition: 0.2s ease-in-out;
            width: rem(10);
            height: rem(10);
            path {
              fill: var(--black-font);
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
    }
    .extra_bottom {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      p {
        margin: 0;
        font-weight: 500;
        font-size: rem(10);
        line-height: rem(12);
        color: #7c828d;
        span {
          font-weight: 600;
        }
        &.red {
          color: #ea3e53;
        }
      }
    }
  }
  &_right {
    width: calc(100% - 306px);
    .daily_workload {
      display: flex;
      height: rem(100);
      &.hidden {
        display: none;
      }
      > div {
        border-right: rem(1) solid #e0e0e0;
      }
      &_item {
        transform: rotate(180deg);
        flex: 1;
        padding: rem(10) rem(5);
        .background {
          display: grid;
          height: 100%;
          width: 100%;
          // background-color: var(--green-2);
          background-color: #a3f5df;
          border-radius: rem(3);
          overflow: hidden;
          .workload {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding: rem(5);
            width: 100%;
            border-radius: rem(3);
            // background-color: var(--green);
            background: linear-gradient(#2cad8a, #55cead);
            &.yellow {
              background: linear-gradient(#ff8f60, #ffc360);
            }
            &.red {
              background: linear-gradient(#e54f52, #ff8789);
            }
            .over_capacity {
              display: flex;
              flex-direction: column;
              transform: rotate(180deg);
              span {
                font-weight: 600;
                font-size: rem(12);
                line-height: rem(14);
                letter-spacing: -0.04em;
                color: var(--white);
              }
              p {
                font-weight: 400;
                font-size: rem(10);
                line-height: rem(12);
                color: var(--white);
              }
            }
            // span {
            //   display: block;
            //   height: 0;
            //   overflow: hidden;
            //   color: var(--white);
            //   transition: 0.3s ease-in-out;
            // }
          }
        }
      }
      &_empty {
        display: flex;
        padding: rem(10) rem(5);
        flex: 1;
        transform: rotate(180deg);
        background: repeating-linear-gradient(
          -45deg,
          #f1f2f2,
          #f1f2f2 8px,
          #e8e9e9 0,
          #e8e9e9 12px
        );
        svg {
          overflow: hidden;
          border-radius: rem(5);
          background: #f0f0f0;
          flex: 1;
          width: 100%;
          height: 100%;
          mask {
            width: 100%;
            height: 100%;
          }
          rect {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .tasks {
      display: grid;
      border: rem(1) solid #e0e0e0;
      border-right: none;
      border-bottom: none;
      height: 0;
      overflow: hidden;
      grid-auto-flow: column;

      &.active {
        height: auto;
      }
      .item {
        display: flex;
        align-items: center;
        column-gap: rem(2);
        margin: rem(2);
        padding: rem(8) rem(4);
        color: var(--text-black);
        border-radius: rem(4);
        cursor: pointer;
        position: relative;
        svg {
          width: 1rem;
          min-width: 1rem;
          height: 1rem;
        }
        p {
          margin: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        span {
          margin-left: auto;
          padding-left: rem(4);
          white-space: nowrap;
        }
      }
    }
  }
}

// workload popover
.workload_popover {
  :global {
    .ant-popover-inner {
      border-radius: rem(10);
      &-content {
        padding: rem(10);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    row-gap: rem(8);
    width: rem(202);
    &_top {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(17);
      color: var(--black-6);
      margin: 0;
    }
    &_bottom {
      display: flex;
      column-gap: rem(8);
      &_left {
        :global {
          .ant-progress-circle {
            .ant-progress-circle-trail {
              stroke: #a3f5df;
            }
            .ant-progress-circle-path {
              stroke: #55cead;
            }
          }
        }
        &.yellow {
          :global {
            .ant-progress-circle .ant-progress-circle-path {
              stroke: #ffc360;
            }
          }
        }
        &.red {
          :global {
            .ant-progress-circle .ant-progress-circle-path {
              stroke: #ff8789;
            }
          }
        }
      }
      &_right {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 400;
          font-size: rem(12);
          line-height: rem(14);
        }
        p {
          color: var(--grey);
          font-weight: 500;
          font-size: rem(12);
          line-height: rem(14);
          margin: 0;
          span {
            color: #44cca7;
            &.yellow {
              color: #ffc360;
            }
            &.red {
              color: #ff8789;
            }
          }
        }
      }
    }
  }
.hidden {
   display: none;
 }
}

