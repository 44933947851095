@import '../../../../../styles/Functions';

.chart {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: var(--white);
  padding: 1rem;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      margin: 0;
    }
  }

  &_body {
    position: relative;
    width: 100%;
    height: rem(350);
  }
}

.popover {

  :global {
    .ant-popover-inner {
      border-radius: rem(4);
      overflow: hidden;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }


  .popover_content {
    display: flex;
    flex-direction: column;

    span {
      cursor: pointer;
      padding: rem(4) rem(22);
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: #F9FAFB;
      }
    }
  }
}