@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-1: #c4ccf8;
  --primary-3: #3b8aff;
  --primary: #3a57e8;
  --primary-dark: #0048b3;

  --grey: #8a92a6;
  --light-grey: #adb5bd;

  --white: #ffffff;
  --white-font: #fbfbfb;

  --black-font: #232d42;
  --border: #e9ecef;
  --background: #e9ecef;

  --red: #ea3e53;
  --orange: #f16a1b;

  --green: rgb(26, 160, 83);
  --green-2: rgba(26, 160, 83, 0.2);

  --black: #000000;
  --black-6: rgba(0, 0, 0, 0.6);
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--background);
  color: var(--black-font);
  // overflow: hidden;
}
