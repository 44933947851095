@import '../../styles/Functions.scss';

.workloads {
  display: flex;
  flex-direction: column;
  row-gap: rem(10);
  background-color: var(--white);
  padding: rem(10) 0;
  overflow: hidden;
  &_header {
    display: flex;

    .left {
      width: rem(309);
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      .picker {
        width: max-content;
        padding: 0;
        :global {
          input {
            font-weight: 400;
            text-align: center;
            font-size: rem(14);
            line-height: rem(17);
            color: var(--black-font);
          }
          span {
            display: none;
          }
        }
      }
      .arrows {
        display: flex;
        column-gap: rem(8);
        margin-right: rem(40);
        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: rem(5);
          cursor: pointer;
          svg {
            transition: 0.2s;
            &:hover {
              path {
                fill: var(--primary);
              }
            }
          }
        }
      }
      .select {
        :global {
          .ant-select-selector {
            height: max-content;
            min-width: rem(88);
            &::after {
              display: none;
            }
          }
          .ant-select-selection-placeholder {
            display: none;
          }
          .ant-select-selection-item {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(15);
            color: var(--black-font);
          }
        }
      }
    }
  }
  &_body {
    display: flex;
    flex-direction: column;
    &_top {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--white);
      .left {
        display: flex;
        align-items: center;
        width: rem(309);
        border: rem(1) solid #e0e0e0;
        padding: rem(8) rem(10);
        p {
          font-size: rem(14);
          line-height: rem(17);
          color: var(--black-font);
          margin: 0;
        }
      }
      .right {
        flex: 1;
        display: flex;
        border-bottom: none;
        .item {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: rem(10);
          border: rem(1) solid #e0e0e0;
          border-left: none;
          span {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(14);
            color: var(--grey);
          }
          &.active span {
            font-weight: 500;
            color: var(--primary);
          }
        }
      }


    }
    &_bottom {
      display: flex;
      flex-direction: column;
    }
  }
}

.lines {
  display: grid;
  grid-template-columns: repeat(7, calc(14.2857%));
  height: 100%;
  width: calc(100% - 309px);
  position: absolute;
  right: 0;
  .line {
    border-right: rem(1) solid #e0e0e0;
    position: relative;
    z-index: 2;
  }
}