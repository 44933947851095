@import '../../../styles/Functions.scss';

.time_line {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex: 1;
    min-height: rem(50);
    border: rem(1) solid var(--border);
    background-color: var(--primary-1);
    border-bottom: none;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(180);
      border-right: rem(1) solid var(--border);
    }
    .right {
      flex: 1;
      display: grid;
      padding: 0 rem(4);
      span {
        display: flex;
        align-items: center;
      }
    }
    :global {
      .daily {
        grid-template-columns: repeat(10, 1fr);
      }
      .weekly {
        grid-template-columns: repeat(7, 1fr);
      }
      .monthly {
        grid-template-columns: repeat(30, 1fr);
      }
    }
  }
  .body {
    border: rem(1) solid var(--border);
    border-bottom: none;
    .grid_cont {
      display: flex;
      min-height: rem(66);
      border-bottom: rem(1) solid var(--border);
      .left {
        display: flex;
        align-items: center;
        width: rem(180);
        border-right: rem(1) solid var(--border);
        padding: 0 rem(8);
        img {
          width: rem(28);
          height: rem(28);
          border-radius: 50%;
          object-fit: contain;
          margin-right: rem(8);
        }
        .info {
          p {
            font-weight: 500;
            font-size: rem(12);
            line-height: rem(14);
            margin: 0;
          }
          span {
            font-weight: 400;
            font-size: rem(11);
            line-height: rem(13);
            color: var(--grey);
          }
        }
      }
      :global {
        .daily {
          grid-template-columns: repeat(60, 1fr);
        }
        .weekly {
          grid-template-columns: repeat(420, 1fr);
        }
        .monthly {
          grid-template-columns: repeat(1800, 1fr);
        }
      }
      .right {
        flex: 1;
        display: grid;
        padding: 0 rem(4);
        .time_line {
          position: relative;
          margin: rem(4) 0;
          border-radius: rem(3);
          overflow: hidden;
          .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            z-index: 1;
            border-right: rem(3) solid transparent;
            width: 100%;
            height: 100%;
            padding: rem(6);
            .time {
              font-weight: 500;
              font-size: rem(10);
              line-height: rem(12);
              color: var(--white);
              padding: rem(2) rem(8);
              border-radius: rem(20);
            }
            .reason {
              font-weight: 500;
              font-size: rem(12);
              line-height: rem(14);
              margin: 0;
            }
          }
          .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: 0.15;
          }
        }
      }
    }
  }
}

.time_line_popover {
  .time {
    font-weight: 500;
    font-size: rem(10);
    line-height: rem(12);
    color: var(--white);
    padding: rem(2) rem(8);
    border-radius: rem(20);
  }
  .reason {
    font-weight: 500;
    font-size: rem(12);
    line-height: rem(14);
    margin: 0;
    margin-top: rem(8);
  }
  :global {
    .ant-popover-inner {
      border-radius: rem(3);
    }
  }
}
