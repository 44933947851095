.container {
    :global {
        .ant-table {
            .ant-table-thead {
                >tr {
                    >th {
                        color: #32363c;
                        font-size: 10px;
                        line-height: 12px;
                        background-color: #fff;
                    }
                }
            }
            .ant-table-thead{
                .ant-table-cell{
                    padding: 16px 8px;
                }
            }
            .ant-table-tbody {
                .ant-table-row {
                    .ant-table-cell {
                        padding: 2px 10px;
                        &:nth-child(2){
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row-reverse;
                            padding: 21px 0;
                        }
                        .ant-avatar{
                            width: 16px !important;
                            height: 26px;
                            overflow: inherit;
                            
                            img{
                                width: 26px;
                                border-radius: 50%;
                            }
                        }
                        
                    }
                }

                tr {
                    td {
                        padding: 0 10px;
                    }
                }

                .ant-table-row {

                    >td {
                        h6 {
                            &:first-child {
                                font-size: 10px;
                                line-height: 12px;
                                color: rgba(0, 0, 0, 0.2);
                            }
                        }

                        a {
                            transition: 0.3s;
                            color: #292D34;
                            font-size: 14px;
                        }
                    }

                    .ant-table-cell-row-hover {
                        a {
                            color: #04A9F4 !important;
                        }
                    }
                }

            }

            .ant-table-cell {
                p {
                    margin-bottom: 0;
                    color: #7C828D;
                    font-size: 12px;
                }

                &::before {
                    width: 0 !important;
                }

                &:not(:first-child) {
                    text-align: center;
                }

                .ant-tag {
                    width: 115px;
                    padding: 20px 0;
                }

                .ant-tag-green {
                    background: #00C875;
                    color: #fff;
                }

                .ant-tag-gold {
                    background: #F9D900;
                    color: #444;
                }

                .ant-tag-has-color {
                    background: #04A9F4;
                    color: #fff;
                }

                .ant-tag-red {
                    background: #EA3E53;
                    color: #fff;
                }
            }

        }
    }
}

.progress {
    width: 150px;
    height: 24px;
    padding-top: 3px;
    border-radius: 50px;
    background-color: #B1B1B1;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    &_text {
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 12px;
    }

    &_protsent {
        border-radius: 50px;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        background-color: #37BC54;
        width: 100%;
    }
}