.header {
    display: flex;
    gap: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 1;
}

a {
    color: #292D34;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 5px;
}

.active {
    color: #4869FE;
    border-bottom: 1px solid #4869FE;
}
