@import '../../styles/Functions.scss';

.employees_layout {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: rem(8);
  padding: rem(24);
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: rem(24);
  }
  .body {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
      background: transparent;
      position: absolute;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 0.5rem;
    }
    &.ant-scrolling-effect {
      width: calc(100% - 0.2rem) !important;
      .header {
        width: calc(100% - 0.2rem) !important;
      }
    }
  }
}
