.container{
    display: flex;
    gap: 20px;
    min-width: calc(100% - 571px);
    :global{
        .ant-pagination{
            display: none;
        }
    }

    &_top{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}