@import '../../styles/Functions.scss';

.container {
  height: 100vh;
  display: flex;
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    .cont {
      display: flex;
      flex-direction: column;
      &_header {
        display: flex;
        align-items: center;
        margin-bottom: rem(40);
        svg {
          margin-right: rem(8);
        }
        p {
          font-weight: 400;
          font-size: rem(33);
          margin: 0;
        }
      }
      &_body {
        display: flex;
        flex-direction: column;
        width: rem(436);
        &_title {
          font-weight: 600;
          font-size: rem(33);
          margin-bottom: 1rem;
          text-align: center;
        }
        &_form {
          :global {
            .ant-form-item {
              &:not(:last-child) {
                margin-bottom: 1rem !important;
              }
              &-label {
                padding-bottom: rem(8);
                label {
                  font-weight: 400;
                  font-size: 1rem;
                  color: #8a92a6;
                  &::before {
                    display: none;
                  }
                }
              }
              input {
                border-radius: rem(4);
                height: rem(44);
              }
              .ant-input-password {
                border-radius: rem(4);
                height: rem(44);
                padding: 0;
                input {
                  height: 100%;
                  padding: rem(4) rem(30) rem(4) rem(11);
                }
                .ant-input-suffix {
                  position: absolute;
                  right: rem(11);
                  top: rem(14);
                }
              }
            }
          }
          .submit_btn {
            display: flex;
            justify-content: center;
            button {
              width: rem(188);
              height: rem(44);
              font-weight: 400;
              font-size: 1rem;
              border-radius: rem(4);
              margin-top: rem(8);
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    background: url(../../assets/images/logoBg.png) no-repeat;
    background-size: cover;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}
