.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 23px;

  &_left {
    display: flex;
    align-items: center;
    gap: 8px;

    h3 {
      color: #101828;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &_domain {
      padding: 2px 8px;
      background: #F9F5FF;
      border-radius: 16px;
      height: 22px;

      h4 {
        color: #6941C6;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
  }

  &_btns {
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      width: 210px;
      height: 44px;
      background: #7F56D9;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      border: 1px solid #7F56D9;

      &:hover {
        background: #7F56D9;
        border: 1px solid #7F56D9;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}