@import '../../styles/Functions.scss';

.not_found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  h1 {
    font-size: rem(188);
    color: var(--black-font);
    margin-bottom: 0;
  }
  p {
    font-size: rem(24);
    color: var(--black-font);
  }
}
