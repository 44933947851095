@import '../../../../styles/Functions.scss';

.workload_modal {
  width: rem(300) !important;
  &_header {
    justify-content: space-between !important;
    p {
      white-space: nowrap;
    }
    :global {
      .ant-form {
        width: rem(70);
        &-item {
          margin: 0;
        }
      }
    }
  }
}
