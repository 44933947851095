@import '../../../styles/Functions.scss';

.employees_modal {
  width: rem(677) !important;
  :global {
    .ant-modal-body {
      padding-right: rem(12) !important;
      padding-left: rem(18) !important;
    }
    .ant-form-item {
      margin-bottom: 1rem;
      &-label {
        label {
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(19);
          color: var(--grey);
          &::before {
            display: none !important;
          }
        }
      }
      input {
        height: rem(44);
        border-radius: rem(4);
        border-color: var(--border);
      }
      .ant-select {
        &-selector {
          border-radius: rem(4) !important;
          border-color: var(--border) !important;
          height: rem(44) !important;
          input {
            height: 100% !important;
          }
          .ant-select-selection-item {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .twice {
    display: flex;
    :global {
      .ant-form-item {
        flex: 1;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
  .file_input {
    display: flex;
    &_label {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(44);
        width: rem(120);
        border-radius: rem(4) 0 0 rem(4);
        border: rem(1) solid var(--border);
        border-right: none;
        cursor: pointer;
      }
      input {
        display: none !important;
      }
    }
    .view_btn {
      height: rem(44);
      border-radius: 0;
      box-shadow: none;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--white-font);
      text-shadow: unset;
    }
    .file_name {
      flex: 1;
      :global {
        .ant-row {
          flex: 1;
          input {
            border-radius: 0 rem(4) rem(4) 0;
            background-color: initial;
            color: initial;
            cursor: initial;
            border-left: none;
            &:hover {
              border-color: var(--border);
            }
          }
        }
      }
    }
    :global {
      .ant-form-item {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .header {
    margin-right: rem(10);
    margin-left: rem(4);
  }
  .body {
    :global {
      .ant-tabs-nav {
        margin-right: rem(10);
        margin-left: rem(4);
      }
    }
  }
  .footer {
    margin-right: rem(10);
    margin-left: rem(4);
  }
  .main_panel {
    max-height: 70vh;
    overflow: auto;
    padding-right: rem(10);
    padding-left: rem(4);
    .top_border,
    .bottom_border {
      position: sticky;
      z-index: 1;
      height: rem(1);
      transition: 0.2s;
      &.active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
    }
    .top_border {
      top: 0;
    }
    .bottom_border {
      bottom: 0;
    }
    .edit_login {
      cursor: pointer;
      color: var(--primary);
      text-decoration: underline;
    }
    .image_preview {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
      background: transparent;
      position: absolute;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      left: 0;
      border-radius: 0.5rem;
    }
    &.ant-scrolling-effect {
      width: calc(100% - 0.2rem) !important;
      .header {
        width: calc(100% - 0.2rem) !important;
      }
    }
  }
}
